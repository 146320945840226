<template>
  <div>
    <p><font size="4">LEGO</font> fans from all over earth are eager to build and bring their creations to life in LEGO Universe, but have you ever wondered who is designing the models that will be in the game at launch? If so, allow us to introduce (big drum roll, please…) Burkhard Schlömer from Germany and Duane Hess, a native of the United States! These professional builders have taken a bit of time from their busy schedules to answer a few questions for the LEGO Universe News Network!
    </p>
    <p>
      <strong><em>Hey guys! Let’s start with a question that’s probably on everyone’s mind: Do you consider yourself a pirate or a ninja?</em></strong>
    </p>
    <p>
      <strong>Burkhard:</strong>
      A ninja! I think. Sporting a wooden leg and a parrot on the shoulder isn’t really my style. Pirates drink too much root beer, and aren’t they always badly shaved?
    </p>
    <p>
      <strong>Duane:</strong>
      I am a pirate. Pirates have more fun; there are fewer rules to live by.
    </p>
    <!-- MISSING: pic163B9ABB-B9CD-46A6-BF12-9912536E983C.jpg -->
    <img alt="Burkhard Schlömer" class="rounded w-200 h-215 leftimage" src="">
    <p>
      <strong>Burkhard Schlömer<br>LEGO Universe model designer</strong>
    </p>
    <p>
      <strong><em>We’ve got one pirate and one ninja, then. So, what do you do in your work as model designers for LEGO Universe?</em></strong>
    </p>
    <p>
      <strong>Burkhard:</strong>
      I usually build from artwork provided by our concept artists, which also allows me to come up with my own creative ideas and suggestions. I build mostly digitally on the computer and only occasionally with physical bricks.
    </p>
    <p>
      <strong>Duane:</strong>
      My job is to take images from our concept artists and render them in LEGO form for use as game assets.<br>
    </p>
    <!-- MISSING: picF062FD6D-F082-44C0-A2E2-2CD71E98E86F.jpg -->
    <img alt="Duane Hess" class="rounded w-200 h-258 leftimage" src="">
    <p>
      <strong>Duane Hess<br>LEGO Universe model designer</strong>
    </p>
    <p>
      <strong><em>Tell us a little about your background with LEGO.</em></strong>
    </p>
    <p>
      <strong>Burkhard:</strong>
      Naturally, I was already a LEGO fan as a kid. During my young adulthood I entered a “LEGO dark age” where I wasn’t really playing with bricks, but I still occasionally snuck in to toy stores to check out the new LEGO sets, and sometimes to buy a set --wrapped as a gift, of course! At that time, it was pretty much unthinkable for adults to buy LEGO for themselves. One day, I read about the LEGO Company signing a contract with Lucasfilm: LEGO Star Wars™! That brought me fully back into the LEGO hobby-- that and being able to build with virtual LEGO bricks on my computer.
    </p>
    <p>
      <strong>Duane:</strong>
      I began playing with LEGO bricks when I was about four or five years old. I then continued begging my parents for LEGO sets at every opportunity up until I was a young adult. I went more underground with my LEGO hobby when I was in high school, but I never completely gave up the brick. I’m thankful for that because, as an adult, I’ve found LEGO building to be an excellent creative outlet.
    </p>
    <p>
      <strong><em>Sounds like you both went through a period where social pressures had you building in secret! What were some of your favorite LEGO sets before that, when you were still kids?</em></strong>
    </p>
    <p>
      <strong>Burkhard:</strong>
      It would have to be a set from the Classic Space theme! I remember really wanting the Space Transporter, set 924, but unfortunately it was gone from store shelves by the time I could buy it with my pocket money, so I picked up its successor, the Star Fleet Voyager, set 6929; that was the first larger LEGO set that I remember buying at our little local toy store, and I still vividly recall carrying it home and putting it together.
    </p>

    <!-- MISSING: pic105F076A-0762-4BBC-BC6F-87B1A834C8A1.jpg -->
    <img class="img-large rounded" alt="LEGO© 6929 Star Fleet Voyager" src="">
    <p>LEGO© 6929 Star Fleet Voyager</p>

    <p>
      <strong>Duane:</strong>
      One of the earliest sets that I remember getting is the Exxon Fuel Pumper, set 554, which I added to hand-me-down bricks from my uncle. But I’d say my favorite LEGO set when I was a kid was the Uranium Search Vehicle, set 6928. I played with it for hours! I enjoyed modifying it, and it was one of the few sets that I rebuild on occasion.
    </p>

    <!-- MISSING: pic352CCDE4-8B32-492E-87A1-00D2628CB024.jpg -->
    <img class="img-large rounded"  alt="LEGO© 6928 Uranium Search Vehicle" src="">
    <p>LEGO© 6928 Uranium Search Vehicle</p>
    <p>
      <strong><em>You both chose LEGO sets from the Classic Space theme! What are your current favorite themes?</em></strong>
    </p>
    <p>
      <strong>Burkhard:</strong>
      Easy! LEGO Star Wars™!
    </p>
    <p>
      <strong>Duane:</strong>
      My favorite LEGO theme is City!
    </p>
    <p>
      <strong><em>Thanks guys!</em></strong>
    </p>
    <p>
      Stay tuned to the LEGO Universe News Network for more from Burkhard and Duane. In part two, they’ll teach us more of their building secrets and tell how they became digital model designers!
    </p>
  </div>
</template>
